import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

import { Loader } from '@front/squirtle'

const withContainer = Component => props => {
  const isReady = hooks.useFormStatus('isReady')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const [{ record_id }] = hooks.useFormState()
  const getStepProps = useCallback((index) => ({ active: (index === 0) || !!isManagingRecord }), [isManagingRecord])

  const mergedProps = {
    getStepProps,
    record_id
  }

  return <Loader isLoading={!isReady}>
    <Component {...mergedProps} />
  </Loader>
}

export default withContainer
