import React, { useCallback } from 'react'
import { ModelForm } from '@front/volcanion'

import Callbacks from './callbacks'
import { useApplication as useMainApplication } from '@abra/hooks/application'

const withForm = Component => props => {
  const { record_id, company_id } = props
  const default_application = _.get(useMainApplication(), 'application_id')
  const formProps = {
    name: 'companyuser_form',
    model_name: 'companyuser',
    record_id,
    related_states: [],
    related_searches: [],
    getEmptyForm: useCallback(Callbacks.getEmptyFormHandler(), []),
    recordToForm: useCallback(Callbacks.recordToFormHandler(), []),
    recordToState: useCallback(Callbacks.recordToStateHandler(), []),
    formToRecord: useCallback(Callbacks.formToRecordHandler(company_id, default_application), [company_id, default_application]),
    getFailedNotification: useCallback(Callbacks.getFailedNotificationHandler(), []),
    populate: [
      'user.auth',
      'user.info',
      'user.customerinfo',
      'companyuser_services.companyservice',
      'contact',
      'blacklisted_domains',
      'exluded_commercial_packages'
    ],
    initialState: { isReadOnly: !!record_id, application: default_application, company: company_id },
    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(), []),
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(), []),
    submitOnEmpty: true
  }

  const mergedProps = {
  }

  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
