
import React from 'react'

import { GridStepper } from '@front/squirtle'

import { Grid, Step, Box } from '@mui/material'

import Dashboard from '@abra/panels/Dashboard'
import FormHeader from '@abra/elements/headers/Form'
import CompanyTab from '@abra/panels/Tab/Company'

import PrimarySection from './Section/Primary'
import ContactsSection from './Section/Contacts'

const Company = ({ getStepProps, record_id }) =>
  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
    <Grid container justifyContent='center'>
      <Grid container item xs={11} rowSpacing={3}>
        <Grid item xs><CompanyTab value={1} /></Grid>
        <Grid container item xs={12} >
          <Grid item xs={12}><FormHeader goBackPath={'/company'} /></Grid>
          <Grid item xs={12}>
            <GridStepper
              groupsCount={[3]}
              activeStep={0}
              orientation="vertical"
              getStepProps={getStepProps}
            >
              <Step><PrimarySection /></Step>
              <Step><ContactsSection /></Step>
              <Dashboard company_id={record_id} />
            </GridStepper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Box>

export default React.memo(Company)
